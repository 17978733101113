<template>
    <div>
     
      <v-alert
        outlined
        v-if="countCC >= 1"
        prominent
        icon="mdi-bell-ring"
        type="error"
        border="left"
      >
        <v-row>
          <v-col class="grow">
            <h2 class="blue--text">
              <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
            </h2>
            <h3>
              แจ้งยกเลิก ใบอนุญาตปฏิบัติหน้าที่ครู งานครูผู้ช่วย จำนวน
              {{ countCC }} ราย
            </h3>
          </v-col>
          <v-col class="shrink">
            <v-btn
              to="/admin/manage_assistant_teacher_data"
              color="dark"
              outlined
            >
              <v-icon>mdi-bell-ring</v-icon> ดำเนินการ</v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </template>
  
  <script>
  export default {
    name: "HRvecAlertAssistantTeacher",
  
    data() {
      return {
        ApiKey: "HRvec2021",
        manage_assistant_teacher: [],
        countCC: ""
      };
    },
  
    async mounted() {
      await this.pertem_assis_teachASCCQueryAll();
    },
  
    methods: {
      async pertem_assis_teachASCCQueryAll() {
        let result = await this.$http.post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_licence_cc: "CC"
        });
        this.manage_assistant_teacher = result.data;
        if(this.manage_assistant_teacher){        
        let data = this.manage_assistant_teacher;
        let counts = 0;
        data.forEach(value => {
        
         counts += 1;   
                 
        });
        this.countCC = counts;
      }
      }
    }
  };
  </script>
  